const awsExports = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: 'ap-northeast-1:b1a0c3c4-0384-4ce3-9fbd-afa228861e20',
    // // REQUIRED - Amazon Cognito Region
    region: 'ap-northeast-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'ap-northeast-1_cfoBGD506',
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: '6ema6kc3a9et3soemc16qegh5k',

    oauth: {
      domain: 'sme-dev-altair-member.auth.ap-northeast-1.amazoncognito.com',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'https://vega.ls-sys-dev.jp/',
      redirectSignOut: 'https://vega.ls-sys-dev.jp/',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  },
  API: {
    endpoints: [
      {
        name: 'Altair',
        endpoint: 'https://api.altair.ls-sys-dev.jp/v1/',
        region: 'ap-northeast-1',
        authenticationType: 'AWS_IAM',
        custom_header: () => {
          return { 'x-api-key': 'KM9i323TIf1oD1VMiSSg11IxGqZw7YFO6AIzHAxM' };
        },
      },
      {
        name: 'Altair_Socket',
        endpoint: 'wss://websocket.api.altair.ls-sys-dev.jp/websocket',
        region: 'ap-northeast-1',
        service: 'execute-api',
      },
      {
        name: 'Deneb', // to distribution_customer.yml (live_endpoint)
        endpoint: 'https://d1vx5jvm8u7nzz.cloudfront.net',
        region: 'ap-northeast-1',
        authenticationType: 'API_KEY',
        custom_header: () => {
          return { 'x-api-key': 'AKS4YWhveJUGLwrjhQgQmr45zBwkzuJyirZmKxJTWN4' };
        },
      },
      {
        name: 'Deneb_Archive', // to distribution_customer.yml (archive_endpoint)
        endpoint: 'https://archives.distribution.ls-sys-dev.jp/v1',
        region: 'ap-northeast-1',
        authenticationType: 'API_KEY',
        custom_header: () => {
          return { 'x-api-key': 'AKS4YWhveJUGLwrjhQgQmr45zBwkzuJyirZmKxJTWN4' };
        },
      },
      {
        name: 'Deneb_Live', // to distribution_customer.yml (live_endpoint)
        endpoint: 'https://venueHash.distribution.ls-sys-dev.jp/v1',
        region: 'ap-northeast-1',
        authenticationType: 'API_KEY',
        custom_header: () => {
          return { 'x-api-key': 'AKS4YWhveJUGLwrjhQgQmr45zBwkzuJyirZmKxJTWN4' };
        },
      },
    ],
  },
};
export default awsExports;
