/**
 * Asynchronously loads the component for SearchResult Page
 */

import * as React from 'react';
import { lazyLoad } from 'utils/loadable';
import { LoadingWrapper } from 'app/components/LoadingWrapper';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

export const SearchResult = lazyLoad(
  () => import('./index'),
  module => module.SearchResult,
  {
    fallback: (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    ),
  },
);
