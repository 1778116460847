import { HomePage } from 'app/containers/HomePage/Loadable';
import { NotFoundPage } from 'app/containers/NotFoundPage/Loadable';
import { Lives } from 'app/containers/Lives/Loadable';
import { Login } from 'app/containers/Login/Loadable';
import { Forget } from 'app/containers/Forget/Loadable';
import { PasswordReset } from 'app/containers/PasswordReset/Loadable';
import { Register } from 'app/containers/Register/Loadable';
import { Search } from 'app/containers/Search/Loadable';
import { SearchResult } from 'app/containers/SearchResult/Loadable';
import { MyPage } from 'app/containers/MyPage/Loadable';
import { PointPurchase } from 'app/containers/PointPurchase/Loadable';
import { PresentTicket } from 'app/containers/PresentTicket/Loadable';
import { Contact } from 'app/containers/Contact/Loadable';
import { ContactConfirmation } from 'app/containers/ContactConfirmation/Loadable';
import { Faq } from 'app/containers/Faq/Loadable';
import { Help } from 'app/containers/Help/Loadable';
import { Withdrawal } from 'app/containers/Withdrawal/Loadable';
import { MyTickets } from 'app/containers/MyTickets/Loadable';
import { InvitationCode } from 'app/containers/InvitationCode/Loadable';
import { PurchaseByCode } from 'app/containers/PurchaseByCode/Loadable';
import { LiveDetail } from 'app/containers/LiveDetail/Loadable';
import { LiveDemo } from 'app/containers/LiveDemo/Loadable';
import { LiveDetailWatch } from 'app/containers/LiveDetailWatch/Loadable';
import { LiveInvitation } from 'app/containers/LiveInvitation/Loadable';
import { LivePurchase } from 'app/containers/LivePurchase/Loadable';
import { LivePurchaseByCode } from 'app/containers/LivePurchaseByCode/Loadable';
import { PurchaseConfirm } from 'app/containers/PurchaseConfirm/Loadable';
import { PointHistory } from 'app/containers/PointHistory/Loadable';
import { VerifyAccount } from 'app/containers/VerifyAccount/Loadable';
import { Term } from 'app/containers/Term/Loadable';
import { Splash } from 'app/containers/Splash/Loadable';
import { PurchaseTicketHistory } from 'app/containers/PurchaseTicketHistory/Loadable';
import { InformationDetail } from 'app/containers/InformationDetail/Loadable';

const routes = [
  {
    path: '/',
    name: 'homePage',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: HomePage,
  },
  {
    path: '/splash',
    name: 'splash',
    exact: true,
    meta: {
      class: 'page-no-header',
    },
    component: Splash,
  },
  {
    path: '/faq',
    name: 'faq',
    exact: true,
    meta: {
      class: '',
    },
    component: Faq,
  },
  {
    path: '/help',
    name: 'help',
    exact: true,
    meta: {
      class: '',
    },
    component: Help,
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    exact: true,
    meta: {
      class: '',
      authRequired: true,
    },
    component: Withdrawal,
  },
  {
    path: '/term',
    name: 'term',
    exact: true,
    meta: {
      class: 'page-no-header',
      authRequired: false,
    },
    component: Term,
  },
  {
    path: '/news',
    name: 'articles',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: Lives,
  },
  {
    path: '/live/:id',
    name: 'live-detail',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: LiveDetail,
  },
  {
    path: '/live-demo',
    name: 'live-demo',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: LiveDemo,
  },
  {
    path: '/live/:id/watch',
    name: 'live-detail-watch',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: LiveDetailWatch,
  },
  {
    path: '/live/:id/purchase',
    name: 'live-purchase',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: LivePurchase,
  },
  {
    path: '/live/:id/invitation',
    name: 'live-invitation',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: LiveInvitation,
  },
  {
    path: '/live/:id/invitation-code',
    name: 'live-invitation-code',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: InvitationCode,
  },
  {
    path: '/purchase-code',
    name: 'purchase-code',
    exact: true,
    meta: {
      authRequired: true,
      class: '',
    },
    component: PurchaseByCode,
  },
  {
    path: '/live/:id/purchase-code',
    name: 'live-purchase-code',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: LivePurchaseByCode,
  },
  {
    path: '/info/:id',
    name: 'info-detail',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: InformationDetail,
  },
  {
    path: '/login',
    name: 'login',
    exact: true,
    meta: {
      class: 'page-no-header',
      authRequired: false,
    },
    component: Login,
  },
  {
    path: '/forget',
    name: 'forget',
    exact: true,
    meta: {
      class: 'theme-gray',
      authRequired: false,
    },
    component: Forget,
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    exact: true,
    meta: {
      class: 'theme-gray',
      authRequired: false,
    },
    component: PasswordReset,
  },
  {
    path: '/verify-code',
    name: 'verify-code',
    exact: true,
    meta: {
      class: 'theme-dark page-no-header',
      authRequired: false,
    },
    component: VerifyAccount,
  },
  {
    path: '/register',
    name: 'register',
    exact: true,
    meta: {
      class: 'page-no-header',
      authRequired: false,
    },
    component: Register,
  },
  {
    path: '/search',
    name: 'search',
    exact: false,
    meta: {
      authRequired: true,
    },
    component: Search,
  },
  {
    path: '/search-result',
    name: 'search-result',
    exact: false,
    meta: {
      authRequired: true,
    },
    component: SearchResult,
  },
  {
    path: '/mypage',
    name: 'mypage',
    exact: false,
    meta: {
      authRequired: true,
    },
    component: MyPage,
  },
  {
    path: '/point/purchase',
    name: 'point_purchase',
    exact: false,
    meta: {
      authRequired: true,
    },
    component: PointPurchase,
  },
  {
    path: '/tickets/present',
    name: 'ticket_present',
    exact: false,
    meta: {
      authRequired: true,
    },
    component: PresentTicket,
  },
  {
    path: '/my-tickets',
    name: 'my_tickets',
    exact: false,
    meta: {
      authRequired: true,
    },
    component: MyTickets,
  },
  {
    path: '/contact',
    name: 'contact',
    exact: true,
    meta: {
      authRequired: false,
    },
    component: Contact,
  },
  {
    path: '/contact-confirmation',
    name: 'contact-confirmation',
    exact: true,
    meta: {
      authRequired: false,
    },
    component: ContactConfirmation,
  },

  {
    path: '/purchase/confirm',
    name: 'purchase_confirm',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: PurchaseConfirm,
  },
  {
    path: '/point/history',
    name: 'point_history',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: PointHistory,
  },
  {
    path: '/ticket/purchase/history',
    name: 'purchase_history',
    exact: true,
    meta: {
      authRequired: true,
    },
    component: PurchaseTicketHistory,
  },
  {
    path: '',
    name: '',
    exact: false,
    meta: {
      authRequired: false,
    },
    component: NotFoundPage,
  },
];
export default routes;
