/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import routes from 'routes';
import { history } from 'utils/history';
import qs from 'query-string';
import { ToastContainer } from 'react-toastify';
import { Auth } from 'aws-amplify';

export function App() {
  const renderRouting = routes => {
    return routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />);
  };

  const getRedirectAfterLogged = () => {
    return '/';
  };

  const RouteWithSubRoutes = route => {
    document.body.setAttribute('class', '');
    if (route.meta.class) {
      const classArray = route.meta.class.split(' ');
      for (const classItem of classArray) {
        document.body.classList.add(classItem);
      }
    }

    const parsed = qs.parse(route.location.search);
    const isFederatedSignInCallback =
      route.path === '/' && parsed.code && parsed.state ? true : false;

    const remember = localStorage.getItem('auth.remember');
    Auth.configure({
      storage: remember === 'false' ? sessionStorage : null,
    });

    if (
      route.meta.authRequired &&
      localStorage.getItem('auth.currentUser') &&
      sessionStorage.getItem('auth.check') !== 'true'
    ) {
      Auth.currentAuthenticatedUser()
        .then(user => {})
        .catch(err => {
          window.localStorage.removeItem('auth.accessToken');
          window.localStorage.removeItem('auth.currentUser');
          history.push('/login');
        })
        .finally(() => {
          sessionStorage.setItem('auth.check', 'true');
        });
    }

    return (
      <Route
        path={route.path}
        render={props =>
          route.meta.authRequired &&
          !localStorage.getItem('auth.currentUser') &&
          !isFederatedSignInCallback ? (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          ) : route.name === 'login' &&
            localStorage.getItem('auth.currentUser') &&
            !isFederatedSignInCallback ? (
            <Redirect
              to={{
                pathname: getRedirectAfterLogged(),
                state: { from: props.location },
              }}
            />
          ) : (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={route.routes} />
          )
        }
      />
    );
  };

  return (
    <Router history={history}>
      <Helmet titleTemplate="%s - EdgeTech Live" defaultTitle="EdgeTech Live">
        <meta name="description" content="A EdgeTech Live" />
      </Helmet>

      <Switch>{renderRouting(routes)}</Switch>
      <ToastContainer />
    </Router>
  );
}
